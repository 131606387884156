import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

export class BeforeTerms extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <Header />
        </div>
        <div>
          <main className="main footer-content terms mt-5">
            <div className="container">
              <h2 className="sectionTitle"> Terms & Conditions</h2>
              <div className="terms-content">
                <p>
                  The following Betting Rules pertaining to the bookmaker
                  Jiyobet (here in after referred to as the Rules) stipulate the
                  manner of accepting bets, paying winnings and resolving
                  disputes, as well as the specific features of certain bets on
                  certain sports. These Rules shall govern any other relations
                  between the bookmaker Jiyobet and the customer.
                </p>
                <p>
                  These Rules shall apply to betting on the website and at
                  Jiyobet betting facilities.
                </p>
                <ul>
                  <li>
                    {" "}
                    1. Bet is a risk-driven agreement for potential winnings
                    entered into between the customer and the bookmaker under
                    the established Rules, where the fulfillment of such
                    agreement is conditioned by an event whose outcome is yet to
                    be determined. Bets are accepted on the conditions offered
                    by the bookmaker.
                  </li>
                  <li>
                    2. Outcome is the result of the event (events) on which the
                    bet was placed.
                  </li>
                  <li>
                    {" "}
                    3. Customer is an individual placing a bet with the
                    bookmaker on an outcome.
                  </li>
                  <li>
                    4. Bet Cancellation is an outcome on which the bet is not
                    settled and winnings are not paid. As per the Rules, in the
                    event of “bet cancellation”, an arrangement between the
                    bookmaker and the customer shall be deemed unconcluded and
                    the stake shall be refunded.
                  </li>
                  <li>
                    {" "}
                    5. Regular Time is the duration of the match subject to the
                    regulations of the relevant sport, including time added by
                    the referee. Regular time does not include extra time,
                    overtime(s), penalty shootouts, etc.
                  </li>
                </ul>
              </div>
            </div>
          </main>
        </div>
        <div>
          <BeforeLoginFooter />
        </div>
      </div>
    );
  }
}

export default BeforeTerms;
